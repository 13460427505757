var render = function render(){var _vm=this,_c=_vm._self._c;return _c('treeselect',{attrs:{"options":_vm.options,"value":_vm.value,"searchable":false,"show-count":true,"default-expand-level":1},scopedSlots:_vm._u([{key:"option-label",fn:function({
      node,
      shouldShowCount,
      count,
      labelClassName,
      countClassName,
    }){return _c('label',{class:labelClassName},[_vm._v(" "+_vm._s(node.isBranch ? 'Branch' : 'Leaf')+": "+_vm._s(node.label)+" "),(shouldShowCount)?_c('span',{class:countClassName},[_vm._v("("+_vm._s(count)+")")]):_vm._e()])}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }